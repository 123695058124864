import React from "react"
import Layout from "../components/layout"
import Logo from "../components/images/logo"
import LogoMobileSmall from '../components/images/logo-mobile-small'
import Seo from "../components/seo"
import { motion } from 'framer-motion'
import AnimationFadeIn from "../animations/animation-fade-in";

const ContactPage = (props) => (
    <Layout>

        <Seo title="Kontakt" />
        <h1 className="subpage--headline">kontakt</h1>
        <Logo title="ps:dreizehn GmbH" />
        <LogoMobileSmall title="ps:dreizehn GmbH" />
        <div className="content-wrapper">
            <div className="content">
                <motion.div variants={AnimationFadeIn} transition="spring" initial="hidden" animate="visible" exit="exit">
                    <p>
                        Telefon: <a href="tel:+493722600235">+493722 6002 35</a><br />
                        Mail: <a href="mailto:info@psdreizehn.de">info@psdreizehn.de</a><br />
                        Anschrift:<br />
                        ps:dreizehn GmbH<br />
                        Paul-Seydel-Straße 13<br />
                        09212 Limbach-Oberfrohna
                    </p>
                </motion.div>
            </div>
        </div>
    </Layout>
)

export default ContactPage